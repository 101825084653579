import React from "react";
import Util from "../Util";
import Constant from "../Constant";
import BootstrapTable from "react-bootstrap-table-next";
import {Alert} from "react-bootstrap";
import {useHistory} from "react-router-dom";

const parseList = list => {
  return list.map(el => {
    return {
      ...el,
      customerName: el.customerName || "-",
      contactName: el.contactName || "-",
      // activationKey: el.activationKey.substr(0, 9) + "...",
      hardwareId: el.hardwareId || "-",
    }
  })
}
const baseColumns = [
  {
    dataField: 'id',
    text: 'ID',
    sort: true,
    headerStyle: () => ({width: '5%'})
  },
  {
    dataField: 'lastActivatedAt',
    text: 'Activated',
    sort: true,
    formatter: Util.formatDate,
    headerStyle: () => ({width: '20%'})
  }, {
    dataField: 'customerName',
    text: 'Customer Name',
    sort: true,
    headerStyle: () => ({width: '10%'})
  },
  {
    dataField: 'licenceSchema',
    text: 'Lic. schema',
    sort: true,
    headerStyle: () => ({width: '10%'})
  }
  , {
    dataField: 'activationKey',
    text: 'Activation Key',
    sort: true
  }, {
    dataField: 'hardwareId',
    text: 'Hardware ID',
    sort: true,
    headerStyle: () => ({width: '20%'})
  }
]

const purchaseOrderIdColumn = {
  dataField: 'purchaseOrderId',
  text: 'P. O. ID',
  sort: true,
}

export default function LicenceTable({licences, showPurchaseOrderId}) {
  const history = useHistory();

  const resultColumns = [...baseColumns];
  if (showPurchaseOrderId) {
    resultColumns.push(purchaseOrderIdColumn);
  }

  const rowEvents = {
    onDoubleClick: (e, row) => history.push(`${Constant.url.LICENCES}?id=${row.id}`)
  }
  return licences?.length ? <BootstrapTable keyField='id'
                                            data={parseList(licences)}
                                            columns={resultColumns}
                                            hover striped
                                            rowEvents={rowEvents}
    />
    : <Alert variant="info">
      <b>No Results.</b><br/>
    </Alert>
}
